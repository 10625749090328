app.controller('KontakCtrl', ['$scope', '$http', function($scope, $http) {

  var timeArrive    = Math.round(new Date().getTime() / 1000);
  $scope.hasFilled  = false;
  $scope.pesan = {
    nama: '', email: '', institusi: '', alamat: '', telepon: '', pesan: '',
    blank: '', time: timeArrive
  };
  $scope.save = false;
  $scope.savePesan = () => {
    $scope.save = true;
    
  };

}]);