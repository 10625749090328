'use strict';

var app = angular.module('webapp', ['ngAnimate']).config(['$httpProvider', function ($httpProvider) {

  $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
  $httpProvider.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
  $httpProvider.defaults.transformRequest = [function (data) {
    return angular.isObject(data) && String(data) !== '[object File]' ? jQuery.param(data) : data;
  }];
}]).run(['$rootScope', function ($rootScope) {}]);

app.controller('MainCtrl', ['$scope', '$location', function ($scope, $location) {

  $scope.mainPath = $location.absUrl().split('/')[3];
  console.log($scope.apiUrl);
}]);

app.directive('inputMask', function () {
  return function ($scope, elm, attrs) {
    var opts = {};
    if (!angular.isUndefined(attrs.maskReverse)) opts.reverse = attrs.maskReverse;
    if (!angular.isUndefined(attrs.maskSelectonfocus)) opts.selectOnFocus = attrs.maskSelectonfocus;
    if (!angular.isUndefined(attrs.maskClearifnotmatch)) opts.clearIfNotMatch = attrs.maskClearifnotmatch;
    if (opts === {}) elm.mask(attrs.inputMask);else elm.mask(attrs.inputMask, opts);
  };
});

/**
 * show error on input
 * http://blog.yodersolutions.com/bootstrap-form-validation-done-right-in-angularjs/
 */
app.directive('showErrors', function () {
  return {
    restrict: 'A',
    require: '^form',
    link: function link(scope, el, attrs, formCtrl) {
      var $input = $(el.find('[name]')[0]),
          name = $input.attr('name');
      $input.on('keyup blur', function () {
        el.toggleClass('has-error', formCtrl[name].$invalid);
      });
    }
  };
});